import { isAfter } from 'date-fns';

export const isUntilAfterFrom = (effectiveUntil: Date, effectiveFrom: Date): boolean =>
    !!effectiveUntil && !!effectiveFrom && isAfter(effectiveUntil, effectiveFrom);

type DateRange = {
    effectiveFrom: Date | null;
    effectiveUntil: Date | null;
};

export const validateDateCurrent = (data: DateRange) => {
    if (data.effectiveFrom === null && data.effectiveUntil && data.effectiveUntil <= new Date()) {
        return false;
    }
    return true;
};

export const validateDateUntilAfterFrom = (data: DateRange) => {
    if (!data.effectiveFrom || !data.effectiveUntil) {
        return true;
    }
    return isUntilAfterFrom(data.effectiveUntil, data.effectiveFrom);
};
