/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ApiError {
  location?: string | null;
  message: string;
  reason: ErrorApiErrorReason;
}

export type Bic = string;

export type ContinuationToken = string;

/** ISO 4217 alphabetic currency code. */
export type Currency = string;

/** String with max 50 letters, digits, _ or - */
export type ExternalReference = string;

export type Iban = string;

export type IdempotencyHeader = string;

/** @example {"key":"val"} */
export type JsonNotes = any;

export type LegalForm = string;

/** Represents a monetary value of a specific currency. */
export interface Monetary {
  /** fixed point decimal. decimal places as defined by currency */
  amount: string;
  /** ISO 4217 alphabetic currency code */
  currency: string;
}

export type NaceCode = string;

/**
 * Pagination size as expressed as a 64 bit number.
 * @format int64
 * @min 1
 * @max 100
 */
export type PaginationSize = number;

export interface PhoneNumber {
  /**
   * @format uint32
   * @min 1
   */
  countryCode: number;
  /** RFC 3966 */
  number: string;
}

export interface AuthAccess {
  /** access token data */
  accessToken: AuthToken;
  /** refresh token data */
  refreshToken: AuthToken;
}

export interface AuthChallenge {
  /** the id of this entity */
  id: string;
  /** the user you are trying to authenticate */
  userId: string;
  /** the actor that you are trying to impersonate */
  actorId: string;
  /** crypto key id you are challenging */
  cryptokeyId: string;
  /** JWT payload that you have to sign with your matching private key */
  claims: AuthChallengeClaims;
}

export interface AuthChallengeClaims {
  /** id of the crypto key challenge */
  jti: string;
  /** challenge string */
  sub: string;
  /** issuing time */
  exp: number;
  /** issuing time */
  iat: number;
}

export interface AuthCreateChallenge {
  /** the actor that you are trying to impersonate (defaults to primary actor) */
  actorId?: string | null;
  /** crypto key id you are challenging */
  cryptokeyId: string;
}

export interface AuthCreatePkceChallenge {
  /** id of oauth provider to challenge */
  oauthProviderId: string;
  /** oauth provider will redirect to this url */
  redirectUrl: string;
  /** the actor that you are trying to impersonate (defaults to primary actor) */
  actorId?: string | null;
}

export interface AuthDateTimes {
  /** expiration time */
  expires: string;
}

export enum AuthGrantType {
  REFRESH_TOKEN = "REFRESH_TOKEN",
}

export interface AuthPasswordId {
  /** password id */
  id: string;
}

export type AuthPasswordIdentifier =
  | ({
      kind: "ID";
    } & AuthPasswordId)
  | ({
      kind: "LABEL";
    } & AuthPasswordLabel);

export interface AuthPasswordLabel {
  /** password label */
  label: string;
}

export interface AuthPkceChallenge {
  /** auth url to call */
  authUrl: string;
}

export interface AuthRedeemResetToken {
  /** reset token to redeem */
  token: string;
}

export interface AuthRenewal {
  /** Grant type for token renewal */
  grantType: AuthGrantType;
  /** your refresh token */
  token: string;
}

export interface AuthToken {
  /** the id of this entity */
  id: string;
  /** times of this entity */
  datetimes: AuthDateTimes;
  /** the JWT */
  token: string;
}

export interface AuthTotpAuthentication {
  /** the actor that you are trying to impersonate (defaults to primary actor) */
  actorId?: string | null;
  /** the TOTP device you want to use (will try all activated devices if not set) */
  totpId?: string | null;
  /** A TOTP code generated by your authenticator app */
  totpCode: string;
  /** the password you are trying to authenticate against */
  passwordIdentifier?: AuthPasswordIdentifier | null;
  /** clear text password */
  password: string;
}

export interface AuthVerifyChallenge {
  /** The JWT that you have signed with your matching private key */
  token: string;
}

export interface AuthVerifyPkceChallenge {
  /** code retrieved on redirect */
  code: string;
}

export type ErrorApiErrorReason =
  | ({
      type: "MISSING_FIELD";
    } & ErrorMissingField)
  | ({
      type: "UNEXPECTED_FIELD";
    } & ErrorUnexpectedField)
  | ({
      type: "UNKNOWN_VARIANT";
    } & ErrorUnknownVariant)
  | ({
      type: "INVALID_TYPE";
    } & ErrorInvalidType)
  | ({
      type: "INVALID_VALUE";
    } & ErrorInvalidValue)
  | ({
      type: "MISSING_QUERY_ARGUMENT";
    } & ErrorMissingQueryArgument)
  | ({
      type: "INVALID_QUERY_ARGUMENT";
    } & ErrorInvalidQueryArgument)
  | ({
      type: "MISSING_HEADER";
    } & ErrorMissingHeader)
  | ({
      type: "MISSING_AUTH_HEADER";
    } & ErrorMissingAuthHeader)
  | ({
      type: "INVALID_HEADER";
    } & ErrorInvalidHeader)
  | ({
      type: "INVALID_AUTH_HEADER";
    } & ErrorInvalidAuthHeader)
  | ({
      type: "MISSING_PATH_ARGUMENT";
    } & ErrorMissingPathArgument)
  | ({
      type: "INVALID_PATH_ARGUMENT";
    } & ErrorInvalidPathArgument)
  | ({
      type: "NOT_FOUND";
    } & ErrorNotFound)
  | ({
      type: "INTERNAL";
    } & ErrorInternal)
  | ({
      type: "UNAUTHORIZED";
    } & ErrorUnauthorized)
  | ({
      type: "FAILED_PRECONDITION";
    } & ErrorFailedPrecondition)
  | ({
      type: "BAD_GATEWAY";
    } & ErrorBadGateway)
  | ({
      type: "SERVICE_UNAVAILABLE";
    } & ErrorServiceUnavailable)
  | ({
      type: "UNPROCESSABLE_ENTITY";
    } & ErrorUnprocessableEntity)
  | ({
      type: "FORBIDDEN";
    } & ErrorForbidden)
  | ({
      type: "CONFLICT";
    } & ErrorConflict)
  | ({
      type: "NOT_IMPLEMENTED";
    } & ErrorNotImplemented)
  | ({
      type: "GENERIC_BAD_REQUEST";
    } & ErrorGenericBadRequest)
  | ({
      type: "MISDIRECTED_REQUEST";
    } & ErrorMisdirectedRequest);

export type ErrorBadGateway = object;

export type ErrorConflict = object;

export type ErrorFailedPrecondition = object;

export type ErrorForbidden = object;

export type ErrorGenericBadRequest = object;

export type ErrorInternal = object;

export type ErrorInvalidAuthHeader = object;

export type ErrorInvalidHeader = object;

export interface ErrorInvalidPathArgument {
  key: string;
}

export type ErrorInvalidQueryArgument = object;

export interface ErrorInvalidType {
  expected: string;
  value: string;
}

export interface ErrorInvalidValue {
  valueType: string;
  value: string;
}

export type ErrorMisdirectedRequest = object;

export type ErrorMissingAuthHeader = object;

export interface ErrorMissingField {
  objTy: string;
  field: string;
}

export type ErrorMissingHeader = object;

export type ErrorMissingPathArgument = object;

export type ErrorMissingQueryArgument = object;

export interface ErrorNotFound {
  entity: string;
  value?: string | null;
}

export type ErrorNotImplemented = object;

export type ErrorServiceUnavailable = object;

export type ErrorUnauthorized = object;

export interface ErrorUnexpectedField {
  fieldType: string;
  field: string;
}

export interface ErrorUnknownVariant {
  variantType: string;
  variant: string;
}

export type ErrorUnprocessableEntity = object;

export type CreateCryptoChallengeData = AuthChallenge;

export type VerifyCryptoChallengeData = AuthAccess;

export type VerifyPkceChallengeData = AuthAccess;

export type CreatePkceChallengeData = AuthPkceChallenge;

export type RedeemResetTokenData = AuthAccess;

export type TotpAuthenticateData = AuthAccess;

export type RenewData = AuthAccess;
