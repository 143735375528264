/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AccountsCreateAccount,
  AccountsCreateAccountHolder,
  AccountsUpdateAccountStatusRequest,
  AccountsUpdateAccountTagsRequest,
  ApiError,
  CreateAccountData,
  CreateAccountHolderData,
  ListAccountHoldersData,
  ListAccountHoldersParams,
  ListAccountsData,
  ListAccountsParams,
  ListTransactionsData,
  ListTransactionsParams,
  UpdateAccountStatusData,
  UpdateAccountTagsData,
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Accounts<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description ### List accounts Returns a list of accounts. The accounts can be filtered. Common use cases might be: - Filter by creation timestamp, the example would return all [Accounts](../../schemas/accounts.Account) created in january 2000: ``` /v0/accounts?datetimes/created=>=2000-01-01T00:00:00.00Z&datetimes/created=<2000-02-01T00:00:00.00Z ``` Note that the filter key and values have to be URL encoded: ``` /v0/accounts?datetimes%2Fcreated=%3E%3D2000-01-01T00%3A00%3A00.00Z&datetimes%2Fcreated=%3C2000-02-01T00%3A00%3A00.00Z ``` - Filter by external reference ``` /v0/accounts?external_reference=my_reference ``` - Filter by status ``` /v0/accounts?status=ACTIVATED ```
   *
   * @tags accounts
   * @name ListAccounts
   * @summary List accounts
   * @request GET:/v0/accounts
   * @secure
   */
  listAccounts = (query: ListAccountsParams, params: RequestParams = {}) =>
    this.request<ListAccountsData, ApiError>({
      path: `/v0/accounts`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * @description Creates a new [**Account**](../../schemas/accounts.Account) under a [**Tenant**](../../schemas/tenants.Tenant) and associated to an [**Account Holder**](../../schemas/accounts.AccountHolder), both of which need to be provided in the request. A newly created **Account**: * has an associated [*currency*](../../schemas/Currency) (EUR by default and, currently, the only one available). * has no [*IBAN*](../../schemas/Iban) nor [**Cards**](../../schemas/cards.Card) associated to it. Thus, to start loading an **Account** it is necessary to associate a **Payment Instrument** (a [**Bank Transfer**](../../schemas/bank_transfer.BankTransferInstrument) or [**A2A Transfer**](../../schemas/a2a_transfers.Instrument), for example) to it.
   *
   * @tags accounts
   * @name CreateAccount
   * @summary Create account
   * @request POST:/v0/accounts
   * @secure
   */
  createAccount = (data: AccountsCreateAccount, params: RequestParams = {}) =>
    this.request<CreateAccountData, ApiError>({
      path: `/v0/accounts`,
      method: "POST",
      body: data,
      secure: true,
      ...params,
    });
  /**
   * @description ### List account holders Returns a list of account holders. The account holders can be filtered. Common use cases might be: - Filter by creation timestamp, the example would return all [Account Holders](../../schemas/accounts.AccountHolder) created in january 2000: ``` /v0/accounts/holders?datetimes/created=>=2000-01-01T00:00:00.00Z&datetimes/created=<2000-02-01T00:00:00.00Z ``` Note that the filter key and values have to be URL encoded: ``` /v0/accounts/holders?datetimes%2Fcreated=%3E%3D2000-01-01T00%3A00%3A00.00Z&datetimes%2Fcreated=%3C2000-02-01T00%3A00%3A00.00Z ``` - Filter by external reference ``` /v0/accounts?external_reference=my_reference ```
   *
   * @tags accounts
   * @name ListAccountHolders
   * @summary List account holders
   * @request GET:/v0/accounts/holders
   * @secure
   */
  listAccountHolders = (query: ListAccountHoldersParams, params: RequestParams = {}) =>
    this.request<ListAccountHoldersData, ApiError>({
      path: `/v0/accounts/holders`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * @description ### Create account holder An account holder is the entity that links an identity to any number of accounts.
   *
   * @tags accounts
   * @name CreateAccountHolder
   * @summary Create account holder
   * @request POST:/v0/accounts/holders
   * @secure
   */
  createAccountHolder = (data: AccountsCreateAccountHolder, params: RequestParams = {}) =>
    this.request<CreateAccountHolderData, ApiError>({
      path: `/v0/accounts/holders`,
      method: "POST",
      body: data,
      secure: true,
      ...params,
    });
  /**
   * @description ### List transactions Returns a list of transactions. The transactions can be filtered. Common use cases might be: - Filter by value dated timestamp, the example would return all [Transactions](../../schemas/accounts.Transaction) value dated in january 2000: ``` /v0/accounts/transactions?datetimes/value_dated=>=2000-01-01T00:00:00.00Z&datetimes/value_dated=<2000-02-01T00:00:00.00Z ``` Note that the filter key and values have to be URL encoded: ``` /v0/accounts?datetimes%value_dated=%3E%3D2000-01-01T00%3A00%3A00.00Z&datetimes%value_dated=%3C2000-02-01T00%3A00%3A00.00Z ``` - Filter by account id ``` /v0/accounts?account_id=00000000-0000-0000-0000-000000000000 ``` - Filter by amount, the example filters for transactions of more than 10 EUR ``` /v0/accounts?status=>=10.00 ```
   *
   * @tags accounts
   * @name ListTransactions
   * @summary List account transactions
   * @request GET:/v0/accounts/transactions
   * @secure
   */
  listTransactions = (query: ListTransactionsParams, params: RequestParams = {}) =>
    this.request<ListTransactionsData, ApiError>({
      path: `/v0/accounts/transactions`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * @description ### Update account status This endpoints updates the status of an account. A precondition must be set using the `if-match` header. This precondition reflects the status that the account currently has. An account can have the following states: - `UNACTIVATED` - The account is new and has never been `ACTIVATED` - `ACTIVATED` - The account can be used - `LOCKED` - The account cannot be used - `TERMINATED` - The account cannot be used. **This status is final - it cannot be reverted**
   *
   * @tags accounts
   * @name UpdateAccountStatus
   * @summary Update account status
   * @request POST:/v0/accounts/{account_id}/status
   * @secure
   */
  updateAccountStatus = (accountId: string, data: AccountsUpdateAccountStatusRequest, params: RequestParams = {}) =>
    this.request<UpdateAccountStatusData, ApiError>({
      path: `/v0/accounts/${accountId}/status`,
      method: "POST",
      body: data,
      secure: true,
      ...params,
    });
  /**
   * @description ### Update account tags This endpoints updates the tags of an account.
   *
   * @tags accounts
   * @name UpdateAccountTags
   * @request POST:/v0/accounts/{account_id}/tags
   * @secure
   */
  updateAccountTags = (accountId: string, data: AccountsUpdateAccountTagsRequest, params: RequestParams = {}) =>
    this.request<UpdateAccountTagsData, ApiError>({
      path: `/v0/accounts/${accountId}/tags`,
      method: "POST",
      body: data,
      secure: true,
      ...params,
    });
}
