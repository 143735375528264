import { z } from 'zod';

import { zodEnumFromObjKeys } from '../../../zodEnumFromObjKeys';
import { requiredUuidValidation } from '../../requiredUuidValidation';

export const createA2ABasicDetailsStepSchema = z.object({
    accountId: requiredUuidValidation,
    definitionId: requiredUuidValidation,
    holderId: requiredUuidValidation,
    tenantId: requiredUuidValidation,
});

export const createA2ABasicDetailsStepSchemaKeys = zodEnumFromObjKeys(
    createA2ABasicDetailsStepSchema.shape
).Enum;
