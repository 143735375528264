/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ApiError,
  AuthCreateChallenge,
  AuthCreatePkceChallenge,
  AuthRedeemResetToken,
  AuthRenewal,
  AuthTotpAuthentication,
  AuthVerifyChallenge,
  AuthVerifyPkceChallenge,
  CreateCryptoChallengeData,
  CreatePkceChallengeData,
  RedeemResetTokenData,
  RenewData,
  TotpAuthenticateData,
  VerifyCryptoChallengeData,
  VerifyPkceChallengeData,
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Auth<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description ### Create Cryptokey Challenge The cryptokey challenge is an authentication method that requires the API user to prove the ownership of a private key. In order to prove that ownership the API user needs to: - create a challenge for the cryptokey with id `cryptokey_id` - grab the `claims` from the response body - create a signed ([JSON Web Token](https://jwt.io/)) from those claims using the private key - send the JWT to the [verification endpoint](../../operations/verify_crypto_challenge).
   *
   * @tags auth
   * @name CreateCryptoChallenge
   * @summary Create a crypto key challenge
   * @request POST:/v0/auths/cryptokey_challenges
   * @secure
   */
  createCryptoChallenge = (data: AuthCreateChallenge, params: RequestParams = {}) =>
    this.request<CreateCryptoChallengeData, ApiError>({
      path: `/v0/auths/cryptokey_challenges`,
      method: "POST",
      body: data,
      secure: true,
      ...params,
    });
  /**
   * @description ### Verify Cryptokey Challenge The cryptokey challenge is an authentication method that requires the API user to prove the ownership of a private key. In order to prove that ownership the API user needs to: - [create a challenge](../../operations/create_crypto_challenge). - grab the `claims' from the response body - create a signed ([JSON Web Token](https://jwt.io/)) from those claims using the private key - send the signed JWT to this endpoint This endpoint will verify the signature using the stored public key and will verify the claims have not been changed.
   *
   * @tags auth
   * @name VerifyCryptoChallenge
   * @summary Verify a crypto key challenge
   * @request PUT:/v0/auths/cryptokey_challenges/{id}
   * @secure
   */
  verifyCryptoChallenge = (id: string, data: AuthVerifyChallenge, params: RequestParams = {}) =>
    this.request<VerifyCryptoChallengeData, ApiError>({
      path: `/v0/auths/cryptokey_challenges/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });
  /**
   * @description ### Verify OAuth challenge The Dock API supports OAuth with PKCE (Proof Key for Code Exchange) flow. This endpoint requires data provided by the OAuth provider when the redirect endpoint is called. The redirect provides `state` and `code`. The `code` has to be passed in the body, while the `state` is passed as id in the path parameters.
   *
   * @tags auth
   * @name VerifyPkceChallenge
   * @summary Verify a PKCE challenge
   * @request PUT:/v0/auths/oauth/{id}
   * @secure
   */
  verifyPkceChallenge = (id: string, data: AuthVerifyPkceChallenge, params: RequestParams = {}) =>
    this.request<VerifyPkceChallengeData, ApiError>({
      path: `/v0/auths/oauth/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });
  /**
   * @description ### Create OAuth challenge The Dock API supports OAuth with PKCE (Proof Key for Code Exchange) flow. This endpoint prepares a PKCE challenge for the OAuth provider specified in `oauth_provider_id`. It will return the URL of the provider used for authentication. A front-end will usually then redirect the user to this URL. Once the authentication at the OAuth provider has been completed the user is redirect to the configured URL. From that redirect the API user has to grab information required for the [verification endpoint](../../operations/verify_pkce_challenge).
   *
   * @tags auth
   * @name CreatePkceChallenge
   * @summary Create a PKCE challenge
   * @request POST:/v0/auths/pkce_challenges
   * @secure
   */
  createPkceChallenge = (data: AuthCreatePkceChallenge, params: RequestParams = {}) =>
    this.request<CreatePkceChallengeData, ApiError>({
      path: `/v0/auths/pkce_challenges`,
      method: "POST",
      body: data,
      secure: true,
      ...params,
    });
  /**
   * @description ### Redeem reset token The reset token is exchanged for a limited access token. This access token be used to set up new auth methods.
   *
   * @tags auth
   * @name RedeemResetToken
   * @summary Redeem reset token
   * @request POST:/v0/auths/reset_tokens
   */
  redeemResetToken = (data: AuthRedeemResetToken, params: RequestParams = {}) =>
    this.request<RedeemResetTokenData, ApiError>({
      path: `/v0/auths/reset_tokens`,
      method: "POST",
      body: data,
      ...params,
    });
  /**
   * @description ### TOTP authentication The TOTP (Time-based one-time password) authentication is a two-factor authentication method. The API user needs to provider a password as first factor (knowledge) and a TOTP code as second factor (ownership).
   *
   * @tags auth
   * @name TotpAuthenticate
   * @summary TOTP authentication
   * @request POST:/v0/auths/totp
   * @secure
   */
  totpAuthenticate = (data: AuthTotpAuthentication, params: RequestParams = {}) =>
    this.request<TotpAuthenticateData, ApiError>({
      path: `/v0/auths/totp`,
      method: "POST",
      body: data,
      secure: true,
      ...params,
    });
  /**
   * @description ### Token renewal Access tokens expire frequently. Once the access token has expired, the API user can use the refresh token to renew the access token for a longer period of time. Full re-authentication is required when the refresh token has expired. The expiration times are returned as part of the [Access](../../schemas/auth.Access) schema.
   *
   * @tags auth
   * @name Renew
   * @summary Refresh an access token
   * @request POST:/v0/tokens
   * @secure
   */
  renew = (data: AuthRenewal, params: RequestParams = {}) =>
    this.request<RenewData, ApiError>({
      path: `/v0/tokens`,
      method: "POST",
      body: data,
      secure: true,
      ...params,
    });
}
