import { z } from 'zod';

import validationLang from '../../lang';
import { NOTES_MAX_LENGTH } from '../constants';

const notesValidation = z
    .string()
    .trim()
    .max(NOTES_MAX_LENGTH, validationLang.GET_MAX_LENGTH(NOTES_MAX_LENGTH));

export const internalNotesSchema = z.object({
    notesInternal: notesValidation.min(1, validationLang.REQUIRED),
});
export const notesSchema = z.object({
    notes: notesValidation.optional(),
});
