import { z } from 'zod';

import { CardsCardStatus } from '@dock/types-dock-partner';

import validationLang from '../../lang';
import { zodEnumFromObjKeys } from '../../zodEnumFromObjKeys';

const maxLength = 148;

export const changeCardStatusSchema = z.object({
    cardStatus: z.nativeEnum(CardsCardStatus, {
        errorMap: () => ({ message: validationLang.CARD_STATUS }),
    }),
    reason: z
        .string()
        .trim()
        .max(maxLength, validationLang.GET_MAX_LENGTH(maxLength))
        .optional()
        .or(z.literal('')),
});

export const changeCardStatusSchemaKeys = zodEnumFromObjKeys(changeCardStatusSchema.shape).Enum;
