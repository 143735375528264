import { z } from 'zod';

import { A2ATransfersInstrumentStatus } from '@dock/types-dock-partner';

import validationLang from '../../lang';
import { zodEnumFromObjKeys } from '../../zodEnumFromObjKeys';

export const changeA2AStatusSchema = z.object({
    status: z.nativeEnum(A2ATransfersInstrumentStatus, {
        errorMap: () => ({ message: validationLang.A2A_STATUS }),
    }),
});

export const changeA2AStatusSchemaKeys = zodEnumFromObjKeys(changeA2AStatusSchema.shape).Enum;
